import React from 'react';
import cn from 'classnames';

import MobileButton from '../Button/Mobile';

import './style.scss';

const MobileAppsButtons = ({ className, withText, ...props }) => (
  <div className={ cn('MobileAppsButtons', { 'MobileAppsButtons--wrapped': withText }, className) }>
    <MobileButton type="ios" withText={ withText } { ...props } />
    <MobileButton type="android" withText={ withText } { ...props } />
  </div>
);

export default MobileAppsButtons;
