import React from 'react';
import ReactSVG from 'react-svg';

import Logo from '../../assets/svg/logo-dark.svg';
import SEO from '../Seo';

import './style.scss';

import { image, heading, description, metaTitle } from '../../general/availability.json';

const ReplacementView = () => (
  <>
    <SEO title={ metaTitle } />

    <div className="ReplacementView">
      <ReactSVG className="ReplacementView__image" src={ image } alt="CashOpera" />
      <h1 className="ReplacementView__heading">{ heading }</h1>
      <p className="ReplacementView__description">{ description }</p>
      <Logo className="ReplacementView__logo" />
    </div>
  </>
);

export default ReplacementView;
