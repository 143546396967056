import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';

import Logo from '../../assets/svg/logo-dark.svg';
import MenuOpen from '../../assets/svg/hamburger.svg';
import MenuClose from '../../assets/svg/cross.svg';
import MobileAppsButtons from '../../components/MobileAppsButtons';

import getAppRoute from '../../utils/getAppRoute';

import './style.scss';

import navbar from '../../general/navbar.json';

class Navbar extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
      isSticky: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.stickNavigation);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.stickNavigation);
  }

  stickNavigation = () => {
    this.setState({
      isSticky: window.pageYOffset > 0,
    });
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  renderLinks() {
    const { isOpen } = this.state;

    return (
      <div className={ cn('Navbar__menu', { 'Navbar__menu--open': isOpen }) }>
        { navbar.links.map(({ label, url }) => (
          <Link
            key={ label }
            to={ url }
            onClick={ this.toggleMenu }
            className="Navbar__link"
            activeClassName="Navbar__link--active"
            >
            { label }
          </Link>
        )) }

        <MobileAppsButtons secondary className="Navbar__button" />
      </div>
    );
  }

  render() {
    const { isOpen, isSticky } = this.state;

    const navbarClasses = cn(
      'Navbar',
      { 'Navbar--dark': isSticky },
    );

    return (
      <nav className={ navbarClasses }>
        <div className="Navbar__container">
          <Link to={ getAppRoute('HOME') }>
            <Logo className="Navbar__logo" />
          </Link>

          <div className="Navbar__trigger">
            { isOpen ?
              <MenuClose onClick={ this.toggleMenu } className="Navbar__trigger-icon" /> :
              <MenuOpen onClick={ this.toggleMenu } className="Navbar__trigger-icon" />
            }
          </div>

          { this.renderLinks() }
        </div>
      </nav>
    );
  }
}

export default Navbar;
