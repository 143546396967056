import React from 'react';
import { string, object, arrayOf } from 'prop-types';
import Helmet from 'react-helmet';

import metaData from '../../general/seo.json';

const propTypes = {
  author: string,
  description: string,
  lang: string,
  meta: arrayOf(object),
  title: string,
  image: string,
};

const defaultProps = {
  author: '',
  description: '',
  lang: 'en',
  meta: [],
  title: '',
  image: '',
};

function SEO({ description, lang, meta, title, author, image }) {
  const metaTitle = title || metaData.title;
  const metaAuthor = author || metaData.author;
  const metaDescription = description || metaData.description;
  const metaImage = image || metaData.image;

  return (
    <Helmet
      htmlAttributes={ {
        lang,
      } }
      title={ metaTitle }
      meta={ [
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: metaAuthor,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'author',
          content: metaAuthor,
        },
      ]
        .concat(meta) }
    />
  );
};

SEO.propTypes = propTypes;
SEO.defaultProps = defaultProps;

export default SEO;
