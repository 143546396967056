import React from 'react';

import MobileAppsButtons from '../../MobileAppsButtons';

import './style.scss';

import prefooter from '../../../general/prefooter.json';

const Prefooter = () => {
  return (
    <section className="Prefooter">
      <div className="Prefooter__container">
        <h4 className="Prefooter__title">{ prefooter.title }</h4>
        <p className="Prefooter__text">{ prefooter.subtitle }</p>
        <MobileAppsButtons withText className="Prefooter__button" />
      </div>
    </section>
  );
};

export default Prefooter;
