import React from 'react';
import ReactGA from 'react-ga';
import cn from 'classnames';
import { oneOf, bool } from 'prop-types';

import Button from './';

import AndroidIcon from '../../assets/svg/android.svg';
import AppleIcon from '../../assets/svg/apple.svg';

import './style.scss';

import { ios, android } from '../../general/app_buttons.json';

const propTypes = {
  type: oneOf(['android', 'ios']).isRequired,
  withText: bool,
};

const defaultProps = {
  withText: false,
};

const MobileButton = ({ type, withText, ...rest }) => {
  const iconClass = cn({ 'Button__icon': withText });

  const buttonProps = {
    className: cn('Button--static', { 'Button--large': !withText }),
    target: '_blank',
    ...rest,
  };

  const Apple = (
    <Button
      { ...buttonProps }
      onClick={ () => {
        ReactGA.event({
          category: 'Clicked App Button',
          action: 'iOS',
        });
      } }
      href={ ios.url }
    >
      <AppleIcon className={ iconClass } />
      { withText && ios.label }
    </Button>
  );

  const Android = (
    <Button
      { ...buttonProps }
      onClick={ () => {
        ReactGA.event({
          category: 'Clicked App Button',
          action: 'Android',
        });
      } }
      href={ android.url }
    >
      <AndroidIcon className={ iconClass } />
      { withText && android.label }
    </Button>
  );

  if (type === 'ios') {
    return Apple;
  }

  return Android;
};

MobileButton.propTypes = propTypes;
MobileButton.defaultProps = defaultProps;

export default MobileButton;
