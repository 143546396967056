import React from 'react';

import Footer from '../Footer';
import Navbar from '../Navbar';
import Seo from '../Seo';
import ReplacementView from '../ReplacementView';

import metaData from '../../general/seo.json';
import { isSiteUp } from '../../general/availability.json';

const Layout = ({ children }) => {

  if (!isSiteUp) {
    return <ReplacementView />;
  }

  return (
    <>
      <Seo { ...metaData } />
      <Navbar />
      <div>{ children }</div>
      <Footer />
    </>
  );
};

export default Layout;
