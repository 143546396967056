import React from 'react';
import { Link, withPrefix } from 'gatsby';
import ReactSVG from 'react-svg';

import Prefooter from './Prefooter';

import './style.scss';

import footer from '../../general/footer.json';

const Footer = class extends React.Component {
  renderSocialIcons = () => {
    return (
      <>
        { footer.social.socialMedia.map(social => {
          return (
            <a
              className="Footer__social-icon"
              href={ social.url }
              key={ social.url }
              target="_blank"
              rel="noopener noreferrer"
            >
              <ReactSVG src={ social.icon } />
            </a>
          );
        }) }
      </>
    );
  }

  render() {
    const currentYear = new Date().getFullYear();

    return (
      <>
        <Prefooter />

        <footer className="Footer">
          <section className="Footer__main">
            <span className="Footer__greeting">
              <h4 className="Footer__welcome">{ footer.welcomeMessage }</h4>
              <p className="Footer__contact">
                { footer.contact.text }&nbsp;
                <a href={ `mailto:${footer.contact.email}` } className="Footer__contact-mail">
                  { footer.contact.email }
                </a>
              </p>
            </span>

            <span className="Footer__links">
              { footer.links.map(({ label, url }) => (
                <Link to={ url } key={ url } className="Footer__link">{ label }</Link>
              )) }
            </span>
          </section>

          <section className="Footer__details">
            <p className="Footer__policy">
              © { currentYear } { footer.policy.name } •{ ' ' }
              <a
                href={ withPrefix('/assets/CashOpera Terms of Services.pdf') }
                className="Footer__policy-link">{ footer.policy.termsTitle }
              </a>
              { ' ' }•{ ' ' }
              <a
                href={ withPrefix('/assets/CashOpera Privacy Policy.pdf') }
                className="Footer__policy-link">{ footer.policy.policyTitle }
              </a>
            </p>
            <div className="Footer__social">
              <p className="Footer__social-title">{ footer.social.text }</p>
              { this.renderSocialIcons() }
            </div>
          </section>
        </footer>
      </>
    );
  }
};

export default Footer;
