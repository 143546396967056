import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

import './style.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
  text: PropTypes.bool,
  secondary: PropTypes.bool,
};

const defaultProps = {
  onClick: null,
  href: null,
  text: false,
  secondary: false,
};

function Button({ href, to, className, children, text, secondary, ...props }) {
  const classes = classNames(
    className,
    'Button',
    {
      'Button--text': text,
      'Button--secondary': secondary,
    },
  );

  if (to) {
    return <Link { ...props } className={ classes } to={ to }>{ children }</Link>;
  }

  if (href) {
    return <a { ...props } className={ classes } href={ href }>{ children }</a>;
  }

  return <button type="button" { ...props } className={ classes }>{ children }</button>;
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
